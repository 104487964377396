import React from 'react'
import { Layout, Head, Breadcrumbs, ProductItem } from '@common'
import { AttentionSection, CategoriesSection, NewsletterSection } from '@sections'
import './styles/products.scss'

const Products = ({ pageContext }) => {
	const { products, posts, page, categories } = pageContext
	return (
		<Layout seo={pageContext.page.seo}
		siteMetaData={pageContext.page.siteMetaData}>
		<AttentionSection height={350} title={page.name}/>
			<div className='products'>
					<div className='container'>
						<Breadcrumbs
							elements={[
								{ label: 'Kategorie', url: '/categories/' },
								{
									label: page.name,
									url: `/categories/${page.slug}/`,
								},
							]}
						/>
						<div className='products-list'>
							{products.length === 0 && (
									<div className='product-item-message'>
										<h5 className='products-content-title'>
											Brak produktów w tej kategorii
										</h5>
									</div>
							)}

							{products.map((el, k) => (
								<ProductItem {...el} />
							))}
						</div>
				</div>
				{/*
				<CallTherapistSection />
				<NewsletterSection />
				*/}
			</div>
			<CategoriesSection
			title={'Inne kategorie'}
			categories={categories} slider={true}/>
		</Layout>
	)
}

export default Products
